import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import usePortal from 'react-cool-portal';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { format, isAfter, parseISO } from 'date-fns';
import ReactGA from 'react-ga';

import {
  Button,
  ButtonRow,
  Header,
  LoadingDots,
  Modal
} from '../../components';
import { useUser } from '../../context';

import Graph from './Graph';
import { BidPanel, P, Subtitle, SubtitleWrapper } from './BidPanel';
import { GetAsset, PlaceBid } from './queries';
import { AssetPicture } from './AssetPicture';
import { CurrentData, DataText } from './CurrentData';
import { ContestActivity } from './ContestActivity';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;

  @media (min-width: 640px) {
    text-align: left;
  }

  @media (min-width: 1024px) {
    margin: auto 5.125rem;
  }
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.3125rem;
    color: #f7f7f7;
  }
`;

const Input = styled.input`
  font-size: 2.5rem;
  line-height: 3rem;
  background: #fdfdfd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 0;
  border-radius: 3px;
  max-width: 100%;
  margin-bottom: 0.625rem;

  ::placeholder {
    color: #cbcbcb;
  }
`;

const adjustTimeseries = (
  timeseries: [{ price: string; date?: string }],
  coefficient: number = 1
) => {
  const numbered =
    timeseries?.map(({ price }) => ({
      price: Number(price)
    })) ?? [];
  const min = Math.min(...numbered.map(({ price }) => price));

  return numbered.map(({ price }) => ({ price: (price - min) * coefficient }));
};

export const BidPage = () => {
  const { Portal, show, hide, isShow } = usePortal({
    containerId: 'portal',
    defaultShow: false,
    internalShowHide: false
  });
  const { code: codeParam } = useParams();
  const code = codeParam.split('-').join('/');
  const user = useUser();
  const { data, error, loading } = useQuery(GetAsset, {
    variables: { code },
    fetchPolicy: 'cache-and-network'
  });
  const [placeBid, mutation] = useMutation(PlaceBid, {
    refetchQueries: ['getAsset']
  });
  const [bidError, setError] = useState<any>(null);
  const { register, handleSubmit } = useForm();

  const { price, name, timeseries, type } = data?.asset ?? {};
  const { id, closeDate, endDate, bid, type: contestType } =
    data?.listContests.find((c: any) => c.type === 'featured') ??
    data?.listContests?.[0] ??
    {};

  const submitPrediction = async ({ price }: any) => {
    try {
      if (user) {
        await placeBid({ variables: { id, code, price } });
        ReactGA.event({
          category: 'User',
          action: 'Place bid'
        });
      } else {
        setError('Not logged in!');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      show();
    }
  };

  const closed = isAfter(Date.now(), parseISO(closeDate));
  const disabled = mutation.loading || closed || user?.power < 2;

  const graphData = adjustTimeseries(timeseries, price < 1 ? 1000 : 1);

  return (
    <StyledSection>
      <Header>
        {name} ({code})
      </Header>
      <BidPanel data-loading={loading}>
        {!(loading || error) && (
          <>
            <CurrentData>
              <div>
                <P>Current Price:</P>
                <DataText>${price} USD</DataText>
              </div>
            </CurrentData>
            <AssetPicture>
              <img
                src={`/assets/${codeParam}${
                  contestType === 'featured' ? '-feat' : ''
                }.png`}
                alt={name}
              />
              {graphData.length && <Graph data={graphData} />}
            </AssetPicture>
            {bid ? (
              <SubtitleWrapper>
                <Subtitle>
                  Your prediction for
                  <br /> {format(parseISO(endDate), 'PPPp')}
                  <br /> is ${bid} USD
                </Subtitle>
              </SubtitleWrapper>
            ) : (
              <form onSubmit={handleSubmit(submitPrediction)}>
                {!user || user.power > 1 ? (
                  <Subtitle>
                    {closed ? 'Sorry, prediction for' : 'Predict the price on'}
                    <br /> {format(parseISO(endDate), 'PPPp')}
                    {closed && (
                      <>
                        <br /> is closed
                      </>
                    )}
                  </Subtitle>
                ) : (
                  <Subtitle>
                    Sorry, you've got not enough power to make a prediction
                  </Subtitle>
                )}
                <Input
                  type="number"
                  placeholder={price > 1 ? '$1000.00 USD' : '$0.00000 USD'}
                  step={price > 1 ? '.01' : '0.00001'}
                  autoFocus
                  disabled={disabled}
                  {...register('price', { required: true })}
                />
                <Button buttonStyle="success" type="submit" disabled={disabled}>
                  {mutation.loading ? <LoadingDots /> : 'Submit'}
                </Button>
              </form>
            )}
          </>
        )}
      </BidPanel>
      <Portal>
        {bidError ? (
          <Modal title="Opps!" type="alert" hide={hide} isShow={isShow}>
            <p>Login or Sign Up to submit prediction</p>
            <ButtonRow>
              <Link as={Button} shape="sharp" buttonStyle="ghost" to="/login">
                Login
              </Link>
              <Link
                as={Button}
                shape="sharp"
                buttonStyle="alert"
                to="/login/signup"
              >
                Sign up
              </Link>
            </ButtonRow>
          </Modal>
        ) : (
          <Modal title="Success!" type="success" hide={false} isShow={isShow}>
            <p>
              You predicted that {name}
              {type === 'STOCK' && ' shares'} price will change to $
              {mutation.data?.placeBid?.bid}
            </p>
            <ButtonRow>
              <Link
                as={Button}
                shape="sharp"
                buttonStyle="ghost"
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                as={Button}
                shape="sharp"
                buttonStyle="primary"
                to="/assets"
              >
                Assets
              </Link>
            </ButtonRow>
          </Modal>
        )}
      </Portal>
      <ContestActivity code={code} />
      {contestType !== 'featured' && (
        <h3>Get 100 ENSYDR points for the right prediction</h3>
      )}
    </StyledSection>
  );
};
