import styled from 'styled-components';

export const CurrentData = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 640px) {
    order: 3;
    flex-direction: column;
    padding-bottom: 0.75rem;
  }
`;

export const DataText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #f7f7f7;
`;
