import gql from 'graphql-tag';
import { ActivityFragment } from '../ActivityPage/queries';

export const GetAsset = gql`
  query getAsset($code: String) {
    asset(code: $code) {
      code
      name
      price
      type
      timestamp
      timeseries {
        price
      }
    }
    listContests(code: $code) {
      id
      startDate
      endDate
      closeDate
      bid
      type
    }
  }
`;

export const PlaceBid = gql`
  mutation placeBid($code: String!, $price: Decimal!, $id: ID) {
    placeBid(id: $id, code: $code, price: $price) {
      bid
    }
  }
`;

export const GetContestBids = gql`
  query GetContestActivity($code: String!) {
    contestActivity(code: $code) {
      ...ActivityFragment
    }
  }
  ${ActivityFragment}
`;

export const AssetActivitySubscription = gql`
  subscription AssetActivity($code: String!) {
    assetActivity(code: $code) {
      ...ActivityFragment
    }
  }
  ${ActivityFragment}
`;

export const GetAssetChat = gql`
  query GetAssetChat($code: String!) {
    assetChat(code: $code) {
      id
      insertedAt
      text
      user {
        name
        avatar
      }
    }
  }
`;

export const SendMessage = gql`
  mutation SendMessage($code: String!, $message: String!) {
    sendMessage(code: $code, text: $message) {
      code
    }
  }
`;

export const AssetChatSubscription = gql`
  subscription AssetChatMessage($code: String!) {
    messageSent(code: $code) {
      id
      insertedAt
      text
      user {
        name
        avatar
      }
    }
  }
`;
