import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';

import { H2, Header } from '../../components';
import { cryptos, stocks } from '../../fixtures/assets';
import { BidPanel, P, Subtitle, SubtitleWrapper } from '../BidPage/BidPanel';

import { AssetPicture } from '../BidPage/AssetPicture';
import { CurrentData, DataText } from '../BidPage/CurrentData';
import { AssetCard } from './AssetsCard';
import { AssetsGrid } from './AssetsGrid';
import { AssetsSection } from './AssetsSection';
import { WeeklyPanel, WinTitle } from './WeeklyPanel';

const ListFeatured = gql`
  query listFeatured {
    listFeatured {
      id
      code
      startDate
      endDate
      closeDate
    }
    asset(code: "MSFT") {
      code
      price
    }
  }
`;

const FeatureTitle = styled(Subtitle)`
  font-size: 30px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.primary};
`;

const ClosingWarning = styled(FeatureTitle)`
  position: absolute;
  bottom: 0;
  text-align: center;
`;

export const AssetsPage = () => {
  const { data, error, loading } = useQuery(ListFeatured, {
    fetchPolicy: 'cache-and-network'
  });
  const { code, endDate, closeDate } =
    data?.listFeatured?.find((a: any) => a.code === 'MSFT') ?? {};
  const { price } = data?.asset ?? {};
  const now = new Date();
  const close = new Date(parseISO(closeDate));
  const isClosed = now > close;
  const codeDashed = code?.split('/').join('-');

  return (
    <AssetsSection>
      <Header>Play. Predict. Win.</Header>
      <Subtitle>
        Predict future prices for your favorite tickers and win!
      </Subtitle>
      <WeeklyPanel />
      {!error && code && (
        <BidPanel data-loading={loading}>
          {data && (
            <>
              <AssetPicture>
                <Link to={`/bid/${codeDashed}`}>
                  <img src={`/assets/${codeDashed}-feat.png`} alt={code} />
                </Link>
                {!isClosed && (
                  <ClosingWarning>
                    Closing in {formatDistanceToNowStrict(parseISO(closeDate))}
                  </ClosingWarning>
                )}
              </AssetPicture>
              <SubtitleWrapper>
                <FeatureTitle>Featured Asset</FeatureTitle>
              </SubtitleWrapper>
              {endDate && (
                <SubtitleWrapper>
                  {isClosed ? (
                    <Subtitle>
                      Prediction closed, new competition is starting on 7 June
                    </Subtitle>
                  ) : (
                    <Link to={`/bid/${codeDashed}`}>
                      <Subtitle>
                        Predict price of {code} on{' '}
                        {format(parseISO(endDate), 'PPPp')}
                      </Subtitle>
                    </Link>
                  )}
                </SubtitleWrapper>
              )}
              <SubtitleWrapper>
                <WinTitle>Best Prediction wins $20 Netflix voucher!</WinTitle>
              </SubtitleWrapper>
              <CurrentData>
                <div>
                  <P>Current Price:</P>
                  <DataText>${price} USD</DataText>
                </div>
              </CurrentData>
            </>
          )}
        </BidPanel>
      )}
      <H2>Stocks</H2>
      <AssetsGrid>
        {stocks.map(
          (asset) =>
            asset.type !== 'closed' && (
              <AssetCard asset={asset} key={asset.code} />
            )
        )}
      </AssetsGrid>
      <H2>Crypto</H2>
      <AssetsGrid>
        {cryptos.map(
          (asset) =>
            asset.type !== 'closed' && (
              <AssetCard asset={asset} key={asset.code} />
            )
        )}
      </AssetsGrid>
    </AssetsSection>
  );
};
