import React from 'react';
import styled, { css } from 'styled-components';
import { Subtitle, SubtitleWrapper } from '../BidPage/BidPanel';

export const WinTitle = styled(Subtitle)`
  font-size: 1.625rem;
  font-weight: 700;
  color: ${p => p.theme.colors.secondary};
`;

export const panelStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.625rem;
  margin-bottom: 2.125rem;
  padding: 1rem 1.5rem;
  background: rgba(247, 247, 247, 0.3);
  backdrop-filter: blur(2px);
  mix-blend-mode: normal;
  border-radius: 25px;
  text-align: left;

  @media (min-width: 700px) {
    flex-direction: row;
    padding-top: 1rem;
  }

  img:first-child {
    display: none;

    @media (min-width: 700px) {
      display: block;
      height: 100%;
      align-self: center;
      margin-right: 1rem;
    }
  }

  img:last-child {
    grid-area: image;

    @media (max-width: 700px) {
      width: 100px;
    }
  }
`;

export const Panel = styled.div`
  ${panelStyles};
`;

const StyledWrapper = styled(SubtitleWrapper)`
  display: grid;
  grid-template-areas: 
            'text text'
            'note image';

  @media (min-width: 768px) {
    grid-template-areas: 
            'text text'
            'note image';
  }

  @media (min-width: 800px) {
    grid-template-areas: 
            'text image'
            'note image';
  }
  
  ${Subtitle} {
    margin: 0;
    padding: 0;
  }

  ${Subtitle}:not(${WinTitle}) {
    grid-area: text;
    font-size: 1.5rem;

    @media (max-width: 700px) {
      margin-bottom: 1rem;
    }
  }

  ${WinTitle} {
    grid-area: note;
    width: 100%;
  }
`;

type Props = {
  id: string;
  dateTitle: string;
  winTitle: string;
  image: string;
  prizeImage: string;
};

export const WinPanel = ({
  dateTitle,
  winTitle,
  image,
  prizeImage,
  id
}: Props) => (
  <Panel id={id}>
    <img src={image} alt="tournament" />
    <StyledWrapper>
      <Subtitle>{dateTitle}</Subtitle>
      <WinTitle>{winTitle}</WinTitle>
      <img src={prizeImage} alt="prize" />
    </StyledWrapper>
  </Panel>
);
