import styled, { css, keyframes } from 'styled-components';
import { Button } from '../../components';

const gradientAnimation = keyframes`
  0% {
    background-position: 1% 0;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0;
  }
`;

export const loadingStyle = css`
  background: linear-gradient(314deg, #d6d7d7, #51546f, #71dbc8);
  background-size: 600% 600%;
  animation: ${gradientAnimation} 8s ease infinite;
  min-height: 400px;

  @media (min-width: 800px) {
    width: 800px;
  }
`;

export const SubtitleWrapper = styled.div``;

export const BidPanel = styled.div<{ 'data-loading': boolean }>`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin-top: 1.625rem;
  margin-bottom: 2.125rem;
  padding: 1rem 2.25rem;
  mix-blend-mode: normal;
  border-radius: 25px;
  text-align: left;

  backdrop-filter: blur(10px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background: inherit;
  position: relative;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(247, 247, 247, 0.7);
    filter: blur(10px);
    background: inherit;
  }

  &[data-loading='true'] {
    ${loadingStyle}
  }

  form,
  ${SubtitleWrapper} {
    width: 100%;
    z-index: 10;
  }

  @media (min-width: 640px) {
    max-height: 410px;

    form,
    ${SubtitleWrapper} {
      display: flex;
      flex-direction: column;
      width: 40%;
      align-items: flex-start;
      margin-bottom: 0.5rem;
    }
  }

  ${Button} {
    height: 43px;
  }
`;

export const P = styled.p`
  margin: 0;
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #f7f7f7;
`;

export const Subtitle = styled(P)`
  padding-top: 0.75rem;
  margin-bottom: 0.625rem;
  font-size: 25px;
  line-height: 30px;
`;
