import styled from 'styled-components';
import { Header } from '../../components';
import { BidPanel, SubtitleWrapper } from '../BidPage/BidPanel';
import { AssetPicture } from '../BidPage/AssetPicture';

export const AssetsSection = styled.section`
  text-align: center;
  margin: 6.25rem 0;

  ${Header} {
    margin-bottom: 0;
  }

  @media (min-width: 640px) {
    padding: 0 5.125rem;
  }

  ${AssetPicture} {
    width: 100%;

    @media (min-width: 870px) {
      width: 60%;
    }
  }

  ${BidPanel} {
    flex-flow: column nowrap;
    max-height: 100%;

    @media (min-width: 870px) {
      flex-flow: column wrap;
      max-height: 410px;
    }

    ${SubtitleWrapper} {
      width: 100%;

      @media (min-width: 870px) {
        width: 40%;
      }
    }
  }
`;
