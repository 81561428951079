import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  justify-content: center;

  div {
    display: flex;
    overflow: hidden;
    min-width: 14.375rem;
    min-height: 10.625rem;
    border-radius: 5px;
    background-color: #e24c4c;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    max-width: 230px;
    max-height: 170px;
  }

  div:hover {
    box-shadow: none;
    background: rgba(209, 213, 255, 0.2);
    transform: scale(1.025);
  }

  img {
    max-width: 100%;
  }

  p {
    max-width: 230px;
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #f7f7f7;
  }

  a {
    text-decoration: none;

    &:hover p {
      color: #f60070;
    }
  }
`;

type AssetCardProps = {
  asset: {
    code: string;
    name: string;
  };
};

export const AssetCard = ({ asset: { code, name } }: AssetCardProps) => {
  const codeDashed = code.split('/').join('-');
  return (
    <Card>
      <Link to={`/bid/${codeDashed}`}>
        <div>
          <img src={`/assets/${codeDashed}.png`} alt={name} />
        </div>
        <p>
          {name} ({code})
        </p>
      </Link>
    </Card>
  );
};
