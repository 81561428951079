import React from 'react';
import styled from 'styled-components';
import { Subtitle, SubtitleWrapper } from '../BidPage/BidPanel';
import { panelStyles } from '../WinPage/WinPanel';

export const WinTitle = styled(Subtitle)`
  font-weight: 700;
  color: ${(p) => p.theme.colors.secondary};
`;

const StyledWrapper = styled(SubtitleWrapper)`
  display: grid;
  grid-template-areas:
    'header header'
    'text text'
    'note note';

  @media (min-width: 768px) {
    grid-template-areas:
      'header header'
      'text text'
      'note image';
  }

  @media (min-width: 800px) {
    grid-template-areas:
      'header header'
      'text image'
      'note image';
  }

  ${Subtitle} {
    margin: 0;
  }

  p:not(${WinTitle}) {
    grid-area: text;
  }

  p:last-of-type:not(${Subtitle}) {
    grid-area: note;
    color: #f5f5f5;
  }

  img {
    grid-area: image;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Panel = styled.div`
  ${panelStyles};
  padding: 2rem 2.25rem 1rem;

  img:first-child {
    display: block;
  }

  ${WinTitle} {
    grid-area: header;
  }
`;

export const Decor = styled.div`
  position: absolute;
  top: 1.1rem;
  left: -3rem;
  border-bottom: 50px solid red !important;
  border-left: 40px solid transparent !important;
  border-right: 64px solid transparent !important;
  width: 188px;
  transform: rotate(-38.11deg);

  @media (min-width: 480px) {
    top: 0.825rem;
    left: -2.25rem;
  }
`;

export const DecorTitle = styled.p`
  position: absolute;
  top: 0;
  left: -0.5rem;
  transform: rotate(-38.11deg);
  color: #f5f5f5;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  width: min-content;
  text-align: center;
`;

export const WeeklyPanel = () => (
  <Panel>
    <img src="/win/swole_doge.png" alt="win" />
    <StyledWrapper>
      <WinTitle>Collect the most Ensydr points and win a Gaming headset</WinTitle>
      <Subtitle>Between 23 May 2021 - 30 May 2021</Subtitle>
      <p>
        * Accurately predict prices for as many assets as possible to earn more Ensydr points.
      </p>
      <img src="/win/headset.png" alt="headset" />
    </StyledWrapper>
    <Decor />
    <DecorTitle>Weekly Tournament</DecorTitle>
  </Panel>
);
