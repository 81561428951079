export const tournaments = [
  {
    dateTitle:
      'Score most Ensydr Points between 5 - 12 Apr 2021 to win 25 Doge Coins',
    winTitle: 'Make as many accurate predictions as possible everyday to win',
    image: '/win/stonks-weekly.png',
    prizeImage: '/win/doge.png'
  },
  {
    dateTitle:
      'Predict price of Bitcoin for the 16th Apr 2021 20:00 (GMT) Tournament started 6th April 2021',
    winTitle: 'Best Prediction wins 10 ADA - Cardano coins',
    image: '/win/BTC-041821.png',
    prizeImage: '/win/cardano.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 19 - 25 Apr 2021 to win a Polkadot (DOT)',
    winTitle: 'Make as many accurate predictions as possible everyday to win',
    image: '/win/doge-weekly.png',
    prizeImage: '/win/polkadot.png'
  },
  {
    dateTitle:
      'Predict the price of Ethereum for 14th May 2021 0:00 (GMT) Tournament starts 3rd May 2021',
    winTitle: 'Best Prediction wins 0.005 ETH.',
    image: '/win/ETH-050621.png',
    prizeImage: '/win/eth.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 7 - 14 May 2021 to win 25 Doge Coins',
    winTitle: 'Make as many accurate predictions as possible everyday to win.',
    image: '/win/stonks-weekly.png',
    prizeImage: '/win/doge-pink.png'
  },
  {
    dateTitle:
      'Predict the price of Microsoft stock for the 4 June 2021 4:00pm (EDT)',
    winTitle: 'Best prediction wins $20 Netflix voucher',
    image: '/win/MSFT-052221.png',
    prizeImage: '/win/netflix.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 23 - 30 May 2021 to win a Gaming Headset',
    winTitle: 'Make as many accurate predictions as possible everyday to win',
    image: '/win/doge-weekly.png',
    prizeImage: '/win/headset.png'
  },
  {
    dateTitle:
      'Predict the price of Apple stock for the 18th Jun 2021 4:00pm (EDT)',
    winTitle: 'Best Prediction wins a Gaming Keyboard',
    image: '/win/AAPL-060721.png',
    prizeImage: '/win/keyboard.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 8 - 14 June 2021 to win a Gaming Mouse',
    winTitle: 'Make as many accurate predictions as possible everyday to win',
    image: '/win/stonks-weekly.png',
    prizeImage: '/win/mouse.png'
  }
];

export const prizes = [
  {
    image: '/win/doge.png',
    title: '25 Doge Coins',
    condition: 'Most points collected between 5-12 Apr 2021'
  },
  {
    image: '/win/cardano.png',
    title: '10 Cardano ADA',
    condition: 'Predict price of Bitcoin'
  },
  {
    image: '/win/polkadot.png',
    title: 'Polkadot DOT',
    condition: 'Most points collected between 19-25 Apr 2021'
  },
  {
    image: '/win/eth.png',
    title: '0.005 Ethereum ETH',
    condition: 'Predict price of Ethereum'
  },
  {
    image: '/win/doge-pink.png',
    title: '25 Doge Coins',
    condition: 'Most points collected between 7-14 May 2021'
  },
  {
    image: '/win/netflix.png',
    title: '$20 Netflix voucher',
    condition: 'Predict price of MSFT stock'
  },
  {
    image: '/win/headset.png',
    title: 'Gaming headset',
    condition: 'Most points collected between 23-30 May 2021'
  },
  {
    image: '/win/keyboard.png',
    title: 'Gaming keyboard',
    condition: 'Predict price of AAPL stock'
  },
  {
    image: '/win/mouse.png',
    title: 'Gaming mouse',
    condition: 'Most points collected between 8-14 Jun'
  }
];
