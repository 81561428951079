export const winners = [
  {
    name: 'Most score 7 May - 14 May',
    type: 'score',
    date: '2021-05-18T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 950,
    prize: '25 DogeCoins',
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    type: 'featured',
    date: '2021-05-14T21:00:00.000Z',
    winner: 'Maclean Winiata',
    bid: {
      price: '3,727.00',
      date: '9th Mar',
      finalPrice: '3,713.54'
    },
    prize: '0.005 ETH',
  },
  {
    name: 'Most score 19 Apr - 25 Apr',
    type: 'score',
    date: '2021-04-27T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 1050,
    prize: '1 Polkadot',
  },
  {
    name: 'Most score 5 Apr - 12 Apr',
    type: 'score',
    date: '2021-04-16T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 1030,
    prize: '25 DogeCoins',
  },
  {
    name: 'Bitcoin',
    code: 'BTC',
    type: 'featured',
    date: '2021-03-16T21:00:00.000Z',
    winner: 'dmty',
    bid: {
      price: '56,320.00',
      date: '9th Mar',
      finalPrice: '55,799.96'
    },
    prize: '100 DogeCoins',
  },
  {
    name: 'Most score between 27 Mar - 03 Apr',
    type: 'score',
    date: '2021-04-07T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 905,
    prize: '10 Cardano',
  },
  {
    name: 'Most score between 15 Mar - 21 Mar 2021',
    type: 'score',
    date: '2021-03-25T21:00:00.000Z',
    winner: 'Freuseb',
    score: 760,
    prize: '10 Cardano',
  },
  {
    name: 'Bitcoin',
    code: 'BTC',
    type: 'featured',
    date: '2021-03-16T21:00:00.000Z',
    winner: 'dmty',
    bid: {
      price: '56,320.00',
      date: '9th Mar',
      finalPrice: '55,799.96'
    },
    prize: '100 DogeCoins',
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    type: 'featured',
    date: '2021-02-25T21:00:00.000Z',
    winner: 'Freuseb',
    bid: {
      price: '1,853.72',
      date: '22nd Feb',
      finalPrice: '1,628.94'
    },
    prize: '100 DogeCoins',
  },
  {
    name: 'DogeCoin',
    code: 'DOGE',
    type: 'featured',
    date: '2021-02-15T21:00:00.000Z',
    winner: 'Sam',
    bid: {
      price: '0.05438',
      date: '12th Feb',
      finalPrice: '0.05682'
    },
    prize: '$20 Amazon Voucher',
  },
  {
    name: 'GameStop',
    code: 'GME',
    type: 'featured',
    date: '2021-02-08T21:00:00.000Z',
    winner: 'Heisenberg',
    bid: {
      price: '66',
      date: '2nd Feb',
      finalPrice: '63.77'
    },
    prize: '$20 Amazon Voucher',
  },
  {
    name: 'First to 2000 Ensydr Points',
    type: 'challenge',
    date: '2021-01-28T21:00:00.000Z',
    winner: 'Heisenberg',
    prize: '$25 Amazon voucher',
  },
]
