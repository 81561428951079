import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';

const StyledFooter = styled.footer`
  flex-shrink: 0;
  width: 100%;
  max-width: 1024px;

  @media (min-width: 640px) {
    border-top: thin solid rgba(255, 255, 255, 0.15);
  }
`;

const FooterNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: 640px) {
    flex-direction: row;
    margin: 3.625rem 5.25rem 1rem;
    align-items: flex-start;
  }

  a#logo-link {
    @media (max-width: 640px) {
      order: 4;
    }
  }

  img#logo {
    height: 37px;
  }

  @media (max-width: 640px) {
    && > * {
      margin-bottom: 3rem;
    }

    &:before {
      content: ' ';
      width: 120%;
      height: 120%;
      bottom: 0;
      position: absolute;
      background-image: url('/shapes/footer-m.svg');
      background-position: bottom 0 right 0;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
`;

const SiteMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-evenly;
`;

const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2.5625rem;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.9375px;
    color: #cbcbcb;
    margin-bottom: 1rem;
    transition: 0.3s;

    &:hover {
      color: #f60070;
    }
  }
`;

const SocialLinks = styled.div`
  width: 155px;
  h3 {
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -1px;
    color: #cbcbcb;
    margin: 0 0 10px;

    @media (max-width: 640px) {
      display: none;
    }
  }

  a > img {
    margin-right: 32px;
  }
  a:last-child > img {
    margin-right: 0;
  }
`;

const Disclaimer = styled.p`
  color: #c5c5c5;
  font-size: 0.75rem;
`;

export const Footer = ({ loggedIn }: { loggedIn: boolean }) => (
  <StyledFooter>
    <FooterNav>
      <Link id="logo-link" to="/">
        <img id="logo" src="/ensydr-logo.svg" alt="Ensydr" />
      </Link>
      <SiteMenu>
        <MenuColumn>
          <Link to="assets">Play Now</Link>
          <Link to={loggedIn ? 'profile' : 'login'}>My Profile</Link>
          <Link to="leaderboard">Leaderboard</Link>
          <Link to="about/win">Win</Link>
          <Link to="about/roadmap">Roadmap</Link>
        </MenuColumn>
        <MenuColumn>
          <Link to="about">About Us</Link>
          <Link to="about/faq">FAQ</Link>
          <Link to="about/contact">Contact Us</Link>
          <Link to="about/terms">Terms of Service</Link>
          <Link to="about/privacy">Privacy Policy</Link>
        </MenuColumn>
      </SiteMenu>
      <SocialLinks>
        <h3>Follow Us:</h3>
        <a
          href="https://www.facebook.com/groups/1056549574740641"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Social',
              action: 'Click Facebook'
            });
          }}
        >
          <img src="/social/facebook.svg" alt="Facebook" />
        </a>
        <a
          href="https://instagram.com/wantmoneygotmoney/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Social',
              action: 'Click Instagram'
            });
          }}
        >
          <img src="/social/instagram.svg" alt="Instagram" />
        </a>
        <a
          href="https://twitter.com/samkamani"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Social',
              action: 'Click Twitter'
            });
          }}
        >
          <img src="/social/twitter.svg" alt="Twitter" />
        </a>
        <a
          href="https://discord.gg/N9zXXn4FnU"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Social',
              action: 'Click Discord'
            });
          }}
        >
          <img src="/social/discord.svg" alt="Discord" />
        </a>
      </SocialLinks>
    </FooterNav>
    <Disclaimer>DISCLAIMER: Nothing on this website should be considered investment advice or a list of recommendations. Please conduct your own research and due diligence prior to buying any stocks or crypto currency.</Disclaimer>
  </StyledFooter>
);
