import styled from 'styled-components';

export const AssetPicture = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  @media (min-width: 640px) {
    align-self: flex-start;
    width: 60%;
    margin-right: 0.625rem;
    height: 0;
    padding-top: 44.3%;
    background: #51546f;
  }

  img {
    object-fit: contain;
    height: 100%;

    @media (min-width: 640px) {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
