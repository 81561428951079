import styled from 'styled-components';
import { ESymbol } from '../../components';
import React from 'react';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 7px;

  @media (min-width: 640px) {
    border-spacing: 0 20px;
  }
`;

export const THead = styled.thead`
  background-color: #d1d5ff;

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    background: transparent;
  }

  th {
    color: ${p => p.theme.colors.darkest};
  }
`;

export const Row = styled.tr<{ highlight?: boolean }>`
  height: 30px;
  font-size: 1rem;
  line-height: 1.2rem;
  background: ${p => (p.highlight ? '#d1d5ff' : 'rgba(86, 94, 174, 0.5)')};
  color: ${p => (p.highlight ? '#090D33' : '#f7f7f7')};
  margin-bottom: 20px;
  font-weight: bold;

  td:first-child,
  th:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 1rem;
  }
  td:last-child,
  th:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  td:before {
    content: ' ';
    background-image: url('/shapes/e.svg');
  }

  td:first-child {
    font-size: 1.875rem;
    line-height: 2.3125rem;
    color: ${p => (p.highlight ? '#090D33' : '#D1D5FF')};
  }

  td:nth-child(2) {
    color: ${p => (p.highlight ? '#090D33' : '#D1D5FF')};
    line-height: 1.75rem;
  }

  td > ${ESymbol} {
    margin-right: 3px;

    path {
      ${p => p.highlight && 'fill: #090D33!important'}
    }

    @media (max-width: 640px) {
      display: none;
    }
  }

  @media (min-width: 640px) {
    height: 64px;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export type Leaderboard = Array<{
  rank: number;
  id: string;
  name: string;
  coins: number;
  country: string;
  avatar?: string;
}>;

type Props = {
  users: Leaderboard;
  highlight?: string;
  showHead?: boolean;
};

export const LeaderTable = ({ users, showHead = true, highlight }: Props) => (
  <Table>
    {showHead && (
      <THead>
        <Row>
          <th>Rank</th>
          <th>User Name</th>
          <th>Score</th>
          <th>Country</th>
        </Row>
      </THead>
    )}
    <tbody>
      {users.map(d => (
        <Row key={d.rank} highlight={d.id === highlight}>
          <td>{d.rank}</td>
          <td>{d.name}</td>
          <td>
            {d.coins}
          </td>
          <td>{d.country}</td>
        </Row>
      ))}
    </tbody>
  </Table>
);
