import styled from 'styled-components';
import { P, Subtitle } from '../../components';
import React from 'react';
import { prizes } from '../../fixtures/tournaments';

const StyledSection = styled.article`
  @media (max-width: 640px) {
    display: none;
  }

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PrizeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 280px;
  height: 280px;
  padding: 0 24px;
  background: rgba(86, 94, 174, 0.5);
  border-radius: 25px;
  backdrop-filter: blur(2px);
  mix-blend-mode: normal;
  cursor: pointer;

  img {
    width: 140px;
  }

  ${Subtitle} {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
    color: #d1d5ff;
  }
`;

export const PrizesSection = () => (
  <StyledSection>
    {prizes.map(({ image, title, condition }, i) => (
      <PrizeCard
        key={i}
        onClick={() => {
          const section = document.querySelector(`#win-${i}`);
          section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }}
      >
        <img src={image} alt={title} />
        <Subtitle>{title}</Subtitle>
        <P>{condition}</P>
      </PrizeCard>
    ))}
  </StyledSection>
);
