import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  BarChartIcon,
  CupIcon,
  EnsydrLogo,
  GridIcon,
  HomeIcon,
  SettingsIcon,
  TrendUpIcon
} from '../SvgComponents';
import { useAuth } from '../../context';
import { NewsIcon } from '../SvgComponents/NewsIcon';

type Props = {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const StyledMenu = styled.nav<Omit<Props, 'setOpen'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9;
  border-radius: 3px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100%;
  text-align: left;
  padding: 1.875rem 0.375rem 1.875rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  backdrop-filter: blur(2px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background: inherit;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(86, 94, 174, 0.5);
    filter: blur(5px);
    background: inherit;
  }

  @media (min-width: 1280px) {
    transform: translateX(0);
  }

  @media (max-width: 480px) {
    width: 100%;
    background: rgb(86, 94, 174);
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 90vh;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    padding: 3.5rem 1.875rem 1.875rem;

    li {
      display: block;
      opacity: ${(p) => (p.open ? 1 : 0)};
      transform: ${(p) => (p.open ? 'translateX(0)' : 'translateX(-35%)')};
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
      transition-property: transform, opacity;

      @media (min-width: 1280px) {
        opacity: 1;
        transform: translateX(0);
      }
    }

    li,
    a {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 2px;
      color: #f7f7f7;
      margin-bottom: 25px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    li:nth-child(1) {
      transition-delay: 0.15s;
    }
    li:nth-child(2) {
      transition-delay: 0.25s;
    }
    li:nth-child(3) {
      transition-delay: 0.35s;
    }
    li:nth-child(4) {
      transition-delay: 0.45s;
    }
    li:nth-child(5) {
      transition-delay: 0.55s;
    }
    li:nth-child(6) {
      transition-delay: 0.65s;
    }
    li:nth-child(7) {
      transition-delay: 0.75s;
    }

    li:last-child {
      margin-top: auto;
      margin-bottom: 50px;
    }
  }
  ${EnsydrLogo} {
    margin-top: 1.5rem;
  }
`;

const Menu = ({ open, setOpen, ...props }: Props) => {
  const { signout } = useAuth();
  const close = () => setOpen(false);

  return (
    <StyledMenu open={open} aria-hidden={!open} {...props}>
      <Link to="/">
        <EnsydrLogo width={230} height={70} />
      </Link>
      <ul>
        <li>
          <Link to="/dashboard" onClick={close}>
            <HomeIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/assets" onClick={close}>
            <GridIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Contests
          </Link>
        </li>
        <li>
          <Link to="/leaderboard" onClick={close}>
            <BarChartIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Leaderboard
          </Link>
        </li>
        <li>
          <Link to="/activity" onClick={close}>
            <TrendUpIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Activity
          </Link>
        </li>
        <li>
          <Link to="/news" onClick={close}>
            <NewsIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            News
          </Link>
        </li>
        <li>
          <Link to="/about/win" onClick={close}>
            <CupIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Win
          </Link>
        </li>
        <li>
          <Link to="/profile" onClick={close}>
            <SettingsIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />{' '}
            Settings
          </Link>
        </li>
        <li onClick={() => signout()}>Log Out</li>
      </ul>
    </StyledMenu>
  );
};

export default Menu;
