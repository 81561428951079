import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CTASection } from './CTASection';
import { PrizesSection } from './PrizesSection';
import { useUser } from '../../context/AuthContext';

export const HomePage = () => {
  const user = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user)
      navigate('/assets');
  }, [user, navigate]);

  return (
    <>
      <CTASection />
      <PrizesSection />
    </>
  );
};
