import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { loadingStyle } from '../BidPage/BidPanel';
import { Button, LoadingDots } from '../../components';
import { Activity } from './createActivityList';

export const ActivityContainer = styled.div<{ 'data-loading'?: boolean }>`
  padding: 0.25rem 1.25rem;
  background: rgba(86, 94, 174, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 5px;
  width: 100%;

  &[data-loading='true'] {
    ${loadingStyle};
    width: auto !important;
  }
`;
export const ActivityContent = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  max-height: ${(p) => (p.size === 'small' ? '18.75rem' : '60vh')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-y: scroll;
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.75rem;

  ::-webkit-scrollbar {
    width: 0.375rem;
    height: 2rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #d1d5ff;
    border-radius: 5px;
  }

  ${Button} {
    align-self: center;
    margin-bottom: 1rem;
  }
`;

type ActivityEdge = {
  cursor: string;
  node: Activity;
};

type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
};

export type ActivityConnection = {
  edges: ActivityEdge[];
  pageInfo: PageInfo;
};

type Props = {
  activity: Activity[];
  renderItem: (props: { item: Activity; index: number }) => ReactElement;
  size?: string;
  period?: string;
  fetchMore?: any;
  hasMore?: boolean;
  loading?: boolean;
};

export const ActivityBox: FC<Props> = ({
  activity,
  size,
  period = 'MONTH',
  fetchMore,
  hasMore,
  loading,
  renderItem,
  children
}) => {
  const observerRef = useRef<any>(null);
  const [buttonRef, setButtonRef] = useState<any>(null);

  // TODO: consider some InfiniteScroll
  useEffect(() => {
    const options = {
      root: document.querySelector('#activity-list'),
      threshold: 0.2
    };
    observerRef.current = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // @ts-ignore
        entry.target.click();
      }
    }, options);
  }, []);

  useEffect(() => {
    if (buttonRef) {
      observerRef.current.observe(document.querySelector('#load-more-button'));
    }
  }, [buttonRef]);

  const loadMoreItems = loading ? () => {} : fetchMore;

  return (
    <ActivityContainer data-loading={loading}>
      {children}
      <ActivityContent size={size} id="activity-list">
        {activity.map((item: any, index: number) =>
          renderItem({ item, index })
        )}
        {hasMore && (
          <Button
            id="load-more-button"
            ref={setButtonRef}
            buttonStyle="light"
            shape="round"
            disabled={loading}
            onClick={loadMoreItems}
          >
            {loading ? <LoadingDots /> : 'Earlier'}
          </Button>
        )}
      </ActivityContent>
    </ActivityContainer>
  );
};
